import { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { ChevronDownIcon } from '@heroicons/react/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example(props) {
  const {
    isLoadingAssets,
    nfts,
    connectedAccount,
    connectWallet,
    setNftId,
    selected,
    setSelected,
  } = props

  return (
    <Listbox
      value={selected}
      onChange={(e) => {
        setSelected(e)
        setNftId(e.id)
      }}
      disabled={nfts.length === 0}
    >
      {({ open }) => (
        <>
          <div
            className="mt-1 relative"
            onClick={() => {
              if (!connectedAccount) {
                connectWallet()
              }
            }}
          >
            <Listbox.Button className="relative w-full bg-white border border-gray-200 rounded-md pl-3 pr-10 py-3 text-left focus:outline-none focus:ring-0 sm:text-sm cursor-pointer">
              <span className="flex items-center">
                {selected && (
                  <img
                    src={selected.image}
                    alt=""
                    className="flex-shrink-0 h-6 w-6 rounded-full"
                  />
                )}
                {!selected && (
                  <div
                    className={`h-6 w-6 rounded-full ${
                      connectedAccount
                        ? nfts.length === 0
                          ? isLoadingAssets
                            ? 'bg-green-200'
                            : 'bg-yellow-200'
                          : 'bg-green-200'
                        : 'bg-gray-200'
                    }`}
                  />
                )}
                <span className="ml-3 block truncate">
                  {selected
                    ? 'Milady #' + selected.id
                    : connectedAccount
                    ? nfts.length > 0
                      ? 'Select an nft'
                      : isLoadingAssets
                      ? '...'
                      : 'No eligible nfts'
                    : 'Connect wallet'}
                </span>
              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {nfts.map((nft) => (
                  <Listbox.Option
                    key={nft.id}
                    className={({ active }) =>
                      classNames(
                        active
                          ? 'text-white bg-gradient-to-r from-green-500 to-green-400'
                          : 'text-gray-900',
                        'cursor-pointer select-none relative py-2 pl-3 pr-9',
                      )
                    }
                    value={nft}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <img
                            src={nft.image}
                            alt=""
                            className="flex-shrink-0 h-6 w-6 rounded-full"
                          />
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'ml-3 block truncate',
                            )}
                          >
                            {'Milady #' + nft.id}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-green-500',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
