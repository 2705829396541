import BAILOUT_LOGO from './assets/images/Bailout.png'
import WETH_LOGO from './assets/images/weth.png'

const Stake = (props) => {
  return (
    <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 space-y-8">
      <div className="w-full">
        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-semibold tracking-tight text-gray-700 sm:text-3xl">
            Stake FRN
          </h2>
          <p className="mt-1 max-w-3xl mx-auto text-center text-sm text-gray-400">
            Earn WETH and FRN from protocol fees
          </p>
        </div>
        <div className="pt-4 w-full ">
          <div className="bg-white rounded-2xl px-8 pt-4 pb-8 space-y-4">
            <div className="w-full flex flex-row justify-between pb-2">
              <div className="flex flex-col space-y-1 justify-between">
                <div className="text-sm font-medium text-gray-300">
                  Total Staked
                </div>
                <div className="leading-none text-xl text-gray-400">
                  248,701.91 FRN
                </div>
              </div>
              <div className="flex flex-col space-y-1 justify-end items-end">
                <div className="text-sm font-medium text-gray-300">
                  Staking APR
                </div>
                <div className="leading-none text-2xl bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-green-400">
                  82.14%
                </div>
              </div>
            </div>

            <div className="relative w-full h-12 flex items-center justify-between ring-1 ring-brand-gray-primary rounded-lg transition-all duration-200 px-3">
              <input
                type="text"
                className="w-full h-full focus:outline-none placeholder-gray-400 font-medium pl-10 pr-9"
                placeholder="0.00"
              />
              <img
                src={BAILOUT_LOGO}
                className="w-6 h-6 text-gray-400 absolute top-1/2 left-3 -translate-y-1/2 pointer-events-none"
              />
              <button
                type="button"
                className="absolute bg-green-100 px-4 py-1 rounded-md top-1/2 right-3 -translate-y-1/2 "
              >
                <span className="font-medium text-green-400 hover:text-green-500">
                  Max
                </span>
              </button>
            </div>

            <div className=" leading-none text-md font-medium py-4 text-white text-center bg-gradient-to-r from-green-500 to-green-400 w-full rounded-lg cursor-pointer hover:from-green-400 hover:to-green-300">
              Stake
            </div>
          </div>
        </div>
      </div>
      <div className="w-full border-t border-grey-100 pt-6">
        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-semibold tracking-tight text-gray-700 sm:text-3xl">
            FRN-ETH SLP
          </h2>
          <p className="mt-1 max-w-3xl mx-auto text-center text-sm text-gray-400">
            Earn FRN rewards by providing SushiSwap liquidity
          </p>
        </div>
        <div className="pt-4 w-full ">
          <div className="bg-white rounded-2xl px-8 pt-4 pb-8 space-y-4">
            <div className="w-full flex flex-row justify-between pb-2">
              <div className="flex flex-col space-y-1 justify-between">
                <div className="text-sm font-medium text-gray-300">
                  Total Staked
                </div>
                <div className="leading-none text-xl text-gray-400">
                  75,032.02 SLP
                </div>
              </div>
              <div className="flex flex-col space-y-1 justify-end items-end">
                <div className="text-sm font-medium text-gray-300">
                  Staking APR
                </div>
                <div className="leading-none text-2xl bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-pink-600 cursor-pointer">
                  11.52%
                </div>
              </div>
            </div>

            <div className="relative w-full h-12 flex items-center justify-between ring-1 ring-brand-gray-primary rounded-lg transition-all duration-200 px-3">
              <input
                type="text"
                className="w-full h-full focus:outline-none placeholder-gray-400 font-medium pl-10 pr-9"
                placeholder="0.00"
              />
              <img
                src={BAILOUT_LOGO}
                className="w-6 h-6 text-gray-400 absolute top-1/2 left-3 -translate-y-1/2 pointer-events-none"
              />
              <img
                src={WETH_LOGO}
                className="absolute h-5 bg-white border border-grey-500 rounded-full"
                style={{ transform: 'translate(12px, -8px)' }}
              />
              <button
                type="button"
                className="absolute bg-gradient-to-r from-blue-50 to-pink-100 px-4 py-1 rounded-md top-1/2 right-3 -translate-y-1/2 "
              >
                <span className="font-medium bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-pink-500 hover:from-blue-500 hover:to-pink-600">
                  Max
                </span>
              </button>
            </div>

            <div className=" leading-none text-md font-medium py-4 text-white text-center bg-gradient-to-r from-blue-500 to-pink-600 w-full rounded-lg cursor-pointer hover:from-blue-400 hover:to-pink-500">
              Stake
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stake
