import WalletConnect from '@walletconnect/client'
import QRCodeModal from '@walletconnect/qrcode-modal'

export const connectWallet = () => {
  return new Promise(async (resolve, reject) => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((accounts) => {
          resolve(accounts[0])
        })
        .catch((error) => {
          reject(error)
        })
    } else {
      reject('No injected web3 in browser')
    }
  })
}
