import { useState, useEffect } from 'react'
import { getWethBalance, getFrnBalance } from './utils/utils'
import { utils } from 'ethers'
import WETH_LOGO from './assets/images/weth.png'
import BAILOUT_LOGO from './assets/images/Bailout.png'
import BAYC from './assets/images/bayc_logo.png'
import Milady from './assets/images/milady.jpeg'
import Moonbirds from './assets/images/moonbird.png'

export default function WalletBox(props) {
  const {
    isDev,
    weth,
    frn,
    branchLp,
    stakedBranchLp,
    isLoadingAssets,
    assetLoadError,
    mintDevWeth,
    mintDevNft,
    isProd,
  } = props

  const TokenDisplay = (props) => {
    const {
      title,
      subtitle,
      image,
      isLpToken,
      balance,
      isStaked,
      isProd,
    } = props

    return (
      <div
        onClick={() => {
          if (!isProd) {
            if (title === 'WETH') {
              mintDevWeth()
            } else if (title === 'MILADY LP') {
              mintDevNft()
            }
          }
        }}
        className="max-w-full min-w-full inline-flex justify-center overflow-ellipsis px-2 py-2 text-sm font-medium text-gray-800  border bg-gray-50 "
        style={{ borderColor: '#292d344d' }}
      >
        <div className="max-w-full w-full items-center truncate flex flex-row space-x-1">
          <img
            src={isStaked ? BAILOUT_LOGO : image}
            className="h-9 cursor-pointer rounded-full border border-gray-400 "
          />
          {isLpToken && (
            <img
              src={isStaked ? image : BAILOUT_LOGO}
              className="absolute h-5 bg-white border border-gray-400 rounded-full"
              style={{ transform: 'translate(15px, -8px)' }}
            />
          )}
          <div className="w-full flex flex-row justify-between items-center">
            <div className="flex flex-col items-start leading-tight	pl-1">
              <div className="text-md font-semibold">{title}</div>
              <div className="text-sm leading-tight text-gray-400">
                {subtitle}
              </div>
            </div>
            <div className="text-lg font-normal font-mono	text-gray-800">
              {balance}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="bg-white px-4 pt-5 pb-4 sm:px-6">
        <div className="flow-root">
          <div className="w-full flex flex-row justify-between items-start">
            <div className="text-xl leading-6 font-medium text-gray-900 pb-3">
              Wallet Overview{' '}
            </div>
          </div>

          <div className="space-y-3 pb-1">
            <TokenDisplay
              title={'WETH'}
              subtitle={isDev ? 'Mumbai' : 'Wrapped Ether'}
              image={WETH_LOGO}
              isLpToken={false}
              balance={Number(utils.formatEther(weth)).toLocaleString(
                undefined,
                { minimumFractionDigits: 2, maximumFractionDigits: 2 },
              )}
              isProd={isProd}
            />
            <TokenDisplay
              title={'FRN'}
              subtitle={isDev ? 'Mumbai' : 'Bailout Token'}
              image={BAILOUT_LOGO}
              isLpToken={false}
              balance={Number(utils.formatEther(frn))
                .toFixed(2)
                .toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              isProd={isProd}
            />
            <TokenDisplay
              title={'MILADY LP'}
              subtitle={isDev ? 'Mumbai' : 'Liquidity Token'}
              image={Milady}
              isLpToken={true}
              balance={Number(utils.formatEther(branchLp))
                .toFixed(2)
                .toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              isProd={isProd}
            />
            <TokenDisplay
              title={'MILADY SLP'}
              subtitle={isDev ? 'Mumbai' : 'Staked Liquidity'}
              image={Milady}
              isLpToken={true}
              isStaked={true}
              balance={Number(utils.formatEther(stakedBranchLp))
                .toFixed(2)
                .toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              isProd={isProd}
            />
          </div>
        </div>
      </div>
    </>
  )
}
