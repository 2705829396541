/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'

import BAYC from './assets/images/bayc_logo.png'
import Milady from './assets/images/milady.jpeg'
import Moonbirds from './assets/images/moonbird.png'

const locations = [
  {
    name: 'BAYC',
    image: BAYC,

    people: [
      {
        name: 'Low Risk',
        title: '25 ETH',
        email: '8.68%',
        role: '12.06%',
        totalSupply: '260 ETH',
        totalBorrowed: '120 ETH',
      },
    ],
  },
  {
    name: 'Miladys',
    image: Milady,

    people: [
      {
        name: 'Low Risk',
        title: '0.25 ETH',
        email: '2.98%',
        role: '4.16%',
        totalSupply: '24.87 ETH',
        totalBorrowed: '7.52 ETH',
      },
      {
        name: 'High Risk',
        title: '1.00 ETH',
        email: '16.22%',
        role: '19.47%',
        totalSupply: '11.20 ETH',
        totalBorrowed: '5.78 ETH',
      },
    ],
  },

  {
    name: 'Moonbirds',
    image: Moonbirds,
    people: [
      {
        name: 'Low Risk',
        title: '1.00 ETH',
        email: '3.31%',
        role: '5.62%',
        totalSupply: '11.20 ETH',
        totalBorrowed: '5.78 ETH',
      },
      {
        name: 'High Risk',
        title: '5.00 ETH',
        email: '14.72%',
        role: '21.25%',
        totalSupply: '11.20 ETH',
        totalBorrowed: '5.78 ETH',
      },
    ],
  },
  // More people...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  return (
    <div className="w-full h-full mx-auto space-y-4">
      {/*
        <div className="w-full bg-gradient-to-r from-green-500 to-green-400 rounded-xl shadow space-y-4 sm:space-y-5 px-5 sm:px-8 py-4">
          <div className="w-full flex flex-col justify-center items-start">
            <div className="w-full flex flex-row justify-between">
              <div className="sm:flex-auto">
                <h1 className="text-xl leading-6 font-medium text-white pb-1 inline-flex space-x-2">
                  <div>USD-ETH Liquidity Mining</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    />
                  </svg>
                </h1>
                <p className="text-sm text-white">
                  Earn additional yield by staking your USD-ETH SLP tokens.
                </p>
              </div>
              <div className="space-y-1 flex flex-col justify-center">
                <div className="text-2xl text-white">28.4% APY</div>
              </div>
            </div>
          </div>
        </div>
      */}

      <div className="w-full bg-white border border-brand-gray-primary rounded-xl shadow-primary space-y-4 sm:space-y-5 px-5 sm:px-8 py-4 pb-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl leading-6 font-medium text-gray-900 pb-1">
              Branch liquidity
            </h1>
            <p className="text-sm text-gray-700">
              Earn yield on your eth/weth by supplying it as liquidity to a
              branch lending market.
            </p>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Collection
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        TVL
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Utilization
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Max Loan
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Deposit APY
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Borrow APR
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {locations.map((location) => (
                      <Fragment key={location.name}>
                        <tr className="border-t border-gray-200 w-full">
                          <th
                            colSpan={5}
                            scope="colgroup"
                            className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                          >
                            <div className="col-span-5 space-x-2 flex flex-row items-center">
                              <img
                                src={location.image}
                                className="h-6 w-6 rounded-full"
                              />
                              <div> {location.name}</div>
                            </div>
                          </th>
                        </tr>
                        {location.people.map((person, personIdx) => (
                          <tr
                            key={person.email}
                            className={classNames(
                              personIdx === 0
                                ? 'border-gray-300'
                                : 'border-gray-200',
                              'border-t',
                            )}
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 ">
                              {person.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.totalSupply}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.totalBorrowed}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.title}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.email}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.role}
                            </td>
                            <td className=" whitespace-nowrap py-4 text-sm font-medium pr-2 sm:pr-6 ">
                              <div className="w-full h-full flex flex-row justify-end space-x-4">
                                <div className="py-2 px-2 border border-green-200 text-green-500 rounded-lg cursor-pointer hover:border-green-400">
                                  Borrow
                                </div>
                                <div className="py-2 px-2 bg-green-500 text-white rounded-lg cursor-pointer hover:bg-green-400">
                                  Deposit
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
