import { useState, useEffect } from 'react'
import WETH_LOGO from './assets/images/weth.png'
import BAILOUT_LOGO from './assets/images/Bailout.png'
import BAYC from './assets/images/bayc_logo.png'
import Milady from './assets/images/milady.jpeg'
import Moonbirds from './assets/images/moonbird.png'

export default function SetupStepper(props) {
  const { isDev } = props

  return (
    <>
      <div
        className="bg-white pt-7  border-t "
        style={{ borderColor: '#292d344d' }}
      >
        <div className="flow-root">
          <div className="w-full">
            <div className="relative">
              <h2 className="text-center text-2xl leading-8 font-semibold tracking-tight text-gray-700 ">
                Stake FRN
              </h2>
              <p className=" max-w-3xl mx-auto text-center text-sm text-gray-400">
                Earn WETH and FRN from protocol fees
              </p>
            </div>
            <div className="pt-2 w-full ">
              <div className="bg-white rounded-2xl px-6 pb-6 space-y-4">
                <div className="w-full flex flex-row justify-between ">
                  <div className="flex flex-col space-y-1 justify-between">
                    <div className="text-sm font-medium text-gray-300">
                      Total Staked
                    </div>
                    <div className="leading-none text-xl font-mono text-gray-400">
                      0.00 FRN
                    </div>
                  </div>
                  <div className="flex flex-col space-y-1 justify-end items-end">
                    <div className="text-sm font-medium text-gray-300">
                      Staking APR
                    </div>
                    <div className="leading-none font-mono text-2xl bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-green-400">
                      100.00%
                    </div>
                  </div>
                </div>

                <div className="relative w-full h-12 flex items-center justify-between ring-1 ring-brand-gray-primary rounded-lg transition-all duration-200 px-3">
                  <input
                    type="text"
                    className="w-full font-mono h-full focus:outline-none placeholder-gray-400 font-medium pl-10 pr-9"
                    placeholder="0.00"
                  />
                  <img
                    src={BAILOUT_LOGO}
                    className="w-6 h-6 text-gray-400 absolute top-1/2 left-3 -translate-y-1/2 pointer-events-none"
                  />
                  <button
                    type="button"
                    className="absolute bg-green-100 px-4 py-1 rounded-md top-1/2 right-3 -translate-y-1/2 "
                  >
                    <span className="font-medium text-green-400 hover:text-green-500">
                      Max
                    </span>
                  </button>
                </div>

                <div className=" leading-none text-md font-medium py-4 text-white text-center bg-gradient-to-r from-green-500 to-green-400 w-full rounded-lg cursor-pointer hover:from-green-400 hover:to-green-300">
                  Stake
                </div>
              </div>
            </div>
          </div>
          {/*
          <div className="w-full border-t border-grey-100 pt-3">
            <div className="relative">
              <h2 className="text-center text-2xl leading-8 font-semibold tracking-tight text-gray-700 ">
                FRN-ETH SLP
              </h2>
              <p className=" max-w-3xl mx-auto text-center text-sm text-gray-400">
                Earn FRN rewards by providing SushiSwap liquidity
              </p>
            </div>
            <div className="pt-2 w-full ">
              <div className="bg-white rounded-2xl px-6 pb-6 space-y-4">
                <div className="w-full flex flex-row justify-between">
                  <div className="flex flex-col space-y-1 justify-between">
                    <div className="text-sm font-medium text-gray-300">
                      Total Staked
                    </div>
                    <div className="leading-none text-xl text-gray-400">
                      0.00 SLP
                    </div>
                  </div>
                  <div className="flex flex-col space-y-1 justify-end items-end">
                    <div className="text-sm font-medium text-gray-300">
                      Staking APR
                    </div>
                    <div className="leading-none text-2xl bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-pink-600 cursor-pointer">
                      100.00%
                    </div>
                  </div>
                </div>

                <div className="relative w-full h-12 flex items-center justify-between ring-1 ring-brand-gray-primary rounded-lg transition-all duration-200 px-3">
                  <input
                    type="text"
                    className="w-full h-full focus:outline-none placeholder-gray-400 font-medium pl-10 pr-9"
                    placeholder="0.00"
                  />
                  <img
                    src={BAILOUT_LOGO}
                    className="w-6 h-6 text-gray-400 absolute top-1/2 left-3 -translate-y-1/2 pointer-events-none"
                  />
                  <img
                    src={WETH_LOGO}
                    className="absolute h-5 bg-white border border-grey-500 rounded-full"
                    style={{ transform: 'translate(12px, -8px)' }}
                  />
                  <button
                    type="button"
                    className="absolute bg-gradient-to-r from-blue-50 to-pink-100 px-4 py-1 rounded-md top-1/2 right-3 -translate-y-1/2 "
                  >
                    <span className="font-medium bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-pink-500 hover:from-blue-500 hover:to-pink-600">
                      Max
                    </span>
                  </button>
                </div>

                <div className=" leading-none text-md font-medium py-4 text-white text-center bg-gradient-to-r from-blue-500 to-pink-600 w-full rounded-lg cursor-pointer hover:from-blue-400 hover:to-pink-500">
                  Stake
                </div>
              </div>
            </div>
          </div>
  */}
        </div>
      </div>
      {/*
      <div className="w-full flex flex-row justify-center pb-4 mx-auto text-sm text-gray-400 hover:text-gray-500">
        <div className="cursor-pointer">Claim rewards and unstake</div>
      </div>
*/}
    </>
  )
}
