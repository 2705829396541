import { useState } from 'react'
import Dropdown from './Dropdown'

import WETH_LOGO from './assets/images/weth.png'
import BAILOUT_LOGO from './assets/images/Bailout.png'
import BAYC from './assets/images/bayc_logo.png'
import Milady from './assets/images/milady.jpeg'
import Moonbirds from './assets/images/moonbird.png'
import BigNumber from 'bignumber.js'
import { utils } from 'ethers'

import Spinner from './Spinner'

export default function Example(props) {
  const {
    isLoadingAssets,
    giveBranchApproval,
    isApprovingBranch,
    branchHasApproval,
    nfts,
    weth,
    frn,
    branchLp,
    connectedAccount,
    connectWallet,
    isDepositing,
    isBorrowing,
    makeDeposit,
    borrowFromBranch,
    isApprovingFrontDesk,
    frontDeskAllowance,
    approveFrontDesk,
    branchData,
    depositAmount,
    setDepositAmount,
    stakeAmount,
    setStakeAmount,
    selectedNft,
    setSelectedNft,
    borrowAmount,
    setBorrowAmount,
    stakeLpTokens,
    stakedBranchLp,
  } = props

  const [nftId, setNftId] = useState('')

  const handleDepositMax = () => {
    setDepositAmount(Number(utils.formatEther(weth).toString()))
  }

  const handleStakeMax = () => {
    setStakeAmount(utils.formatEther(branchLp).toString())
  }

  const handleBorrowMax = () => {
    setBorrowAmount('0.50')
  }

  const renderDepositWarning = () => {
    if (Number(depositAmount) > Number(utils.formatEther(weth))) {
      return (
        <div className="w-full flex flex-row justify-end leading-none text-xs text-yellow-400">
          Insufficient wallet funds
        </div>
      )
    }
  }

  const renderStakingWarning = () => {
    if (Number(depositAmount) > Number(utils.formatEther(weth))) {
      return (
        <div className="w-full flex flex-row justify-end leading-none text-xs text-yellow-400">
          Insufficient wallet funds
        </div>
      )
    }
  }

  const renderBorrowWarning = () => {
    if (Number(borrowAmount) > 0) {
      if (Number(borrowAmount) > 0.5) {
        return (
          <div className="w-full flex flex-row justify-end leading-none text-xs text-yellow-400">
            Max borrow amount is 0.50 Eth
          </div>
        )
      }
    }
  }

  return (
    <div className="w-full h-full mx-auto">
      {/* AIRDROP SECTION ========================
        <div className="w-full flex flex-row justify-between items-center">
          <div className="flex flex-col">
            <h2 className="text-3xl leading-8 font-semibold tracking-tight text-gray-700 sm:text-3xl">
              Airdrop
            </h2>
            <p className="mt-1 max-w-3xl mx-auto text-sm text-gray-400">
              Milady holders are eligible for a one-time claim of FRN.
            </p>
          </div>
          <div className="flex flex-row space-x-4 justify-center items-center h-full">
            <div className=" leading-none text-md font-medium px-8 py-4 text-white text-center bg-gradient-to-b from-green-500 to-green-400 w-full rounded-lg cursor-pointer hover:from-green-400 hover:to-green-300">
              Claim FRN
            </div>
          </div>
        </div>
      */}

      <div
        className="w-full flex flex-row justify-between items-center px-4 sm:px-6 pt-4 border-b pb-4 "
        style={{
          borderColor: '#292d344d',
        }}
      >
        <div className="hidden  sm:flex  flex-col justify-start items-start">
          <h2 className="text-lg sm:text-3xl sm:leading-8 font-semibold tracking-tight text-gray-700 ">
            MILADY
          </h2>
          <p className="hidden font-mono sm:block mt-1 max-w-3xl text-sm text-gray-400">
            Current floor 0.71 ETH
          </p>
          <p className="block sm:hidden mt-1 max-w-3xl text-xs text-gray-600">
            Floor 0.71 ETH
          </p>
        </div>
        <div className="flex flex-col">
          <h2 className="text-sm sm:text-lg sm:leading-8  tracking-tight text-gray-700 ">
            Total Supply
          </h2>
          <p className="mt-1 font-mono max-w-3xl text-xs sm:text-xl text-gray-400">
            {branchData
              ? Number(
                  BigNumber(branchData.totalSupply).div(BigNumber(10).pow(18)),
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + ' Eth'
              : '0.00 Eth'}
          </p>
        </div>
        <div className="flex flex-col justify-start">
          <h2 className="text-sm sm:text-lg sm:leading-8 tracking-tight text-gray-700 ">
            Total Borrow
          </h2>
          <p className="mt-1 font-mono max-w-3xl text-xs sm:text-xl text-gray-400">
            {branchData
              ? Number(
                  BigNumber(
                    BigNumber(branchData.totalSupply).minus(
                      BigNumber(branchData.totalUnderlying),
                    ),
                  ).div(BigNumber(10).pow(18)),
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + ' Eth'
              : '0.00 Eth'}
          </p>
        </div>
        <div className="flex flex-col">
          <h2 className="text-sm sm:text-lg sm:leading-8 tracking-tight text-gray-700 ">
            Max Loan
          </h2>
          <p className="mt-1 font-mono max-w-3xl text-xs sm:text-xl text-gray-400">
            0.50 Eth
          </p>
        </div>
        <div className="flex flex-col">
          <h2 className="text-sm sm:text-lg sm:leading-8 tracking-tight text-gray-700 ">
            Max Debt
          </h2>
          <p className="mt-1 font-mono max-w-3xl text-xs sm:text-xl text-gray-400">
            0.60 ETH
          </p>
        </div>
      </div>

      <div className="w-full flex-col flex-grow grid grid-cols-1 sm:grid-cols-2 ">
        <div
          className="col-span-1 pt-4 border-r pb-6"
          style={{
            borderColor: '#292d344d',
            alignItems: 'stretch',
          }}
        >
          <div className="w-full flex flex-row justify-between">
            <div className="px-2 sm:px-6 flex flex-col">
              <h2 className="text-3xl leading-8 font-semibold tracking-tight text-gray-700 sm:text-3xl">
                Deposit
              </h2>
              <p className="mt-1 max-w-3xl mx-auto text-sm text-gray-400">
                Earn yield by providing liquidity
              </p>
            </div>
          </div>

          <div className="pt-4 w-full px-6 ">
            <div
              className="bg-white px-4 pt-2 pb-4 space-y-4 border"
              style={{
                borderColor: '#292d344d',
              }}
            >
              <div className="w-full flex flex-row justify-between pb-2">
                <div className="flex flex-col space-y-1 justify-between">
                  <div className="text-sm font-medium text-gray-300 flex flex-row space-x-2">
                    <div className="text-lg text-green-500">Step 1</div>
                  </div>
                  <div className="leading-none text-xs text-gray-400">
                    Deposit WETH and receive LP tokens.
                  </div>
                </div>
                <div className="flex flex-col space-y-1 justify-end items-end">
                  <div className="text-sm font-medium text-gray-300">
                    Variable APY
                  </div>
                  <div className="leading-none text-2xl bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-green-400">
                    {branchData
                      ? Number(
                          BigNumber(branchData.rate)
                            .div(BigNumber(10).pow(BigNumber(18)))
                            .times(
                              BigNumber(branchData.totalUnderlying).div(
                                BigNumber(branchData.totalSupply),
                              ),
                            ),
                        ).toFixed(2) + '%'
                      : '0.00%'}
                  </div>
                </div>
              </div>

              <div className="relative w-full h-12 flex items-center justify-between ring-1 ring-brand-gray-primary rounded-lg transition-all duration-200 px-3">
                <input
                  type="text"
                  className={`w-full h-full focus:outline-none placeholder-gray-400 font-medium pl-10 pr-9 ${
                    connectedAccount !== '' ? '' : 'opacity-60'
                  }`}
                  placeholder="0.00"
                  onChange={(e) => {
                    if (!isNaN(+e.target.value) && connectedAccount !== '') {
                      setDepositAmount(e.target.value)
                    }
                  }}
                  value={depositAmount}
                />
                <img
                  src={WETH_LOGO}
                  className="w-6 h-6 text-gray-400 border border-gray-300 rounded-full absolute top-1/2 left-3 -translate-y-1/2 pointer-events-none"
                />
                <button
                  onClick={() => handleDepositMax()}
                  type="button"
                  className="absolute bg-green-100 px-4 py-1 rounded-md top-1/2 right-3 -translate-y-1/2 "
                >
                  <span className="font-medium text-green-400 hover:text-green-500">
                    Max
                  </span>
                </button>
              </div>

              {renderDepositWarning()}

              <div
                onClick={() => {
                  if (connectedAccount) {
                    if (!isDepositing && !isApprovingFrontDesk) {
                      if (
                        Number(
                          utils.formatEther(frontDeskAllowance).toString(),
                        ) < Number(depositAmount)
                      ) {
                        approveFrontDesk()
                      } else {
                        makeDeposit(utils.parseEther(depositAmount).toString())
                      }
                    }
                  } else {
                    connectWallet()
                  }
                }}
                className="leading-none h-12 text-md font-medium py-4 text-white text-center bg-gradient-to-r from-green-500 to-green-400 w-full rounded-lg cursor-pointer hover:from-green-400 hover:to-green-300"
              >
                {isApprovingFrontDesk && (
                  <div
                    className="w-full flex justify-center"
                    style={{ transform: 'translate(8px, -4px)' }}
                  >
                    <Spinner color="white" />
                  </div>
                )}

                {isDepositing && (
                  <div
                    className="w-full flex justify-center"
                    style={{ transform: 'translate(8px, -4px)' }}
                  >
                    <Spinner color="white" />
                  </div>
                )}
                {!isDepositing &&
                  !isApprovingFrontDesk &&
                  Number(utils.formatEther(frontDeskAllowance).toString()) >=
                    Number(depositAmount) &&
                  'Deposit'}
                {!isDepositing &&
                  !isApprovingFrontDesk &&
                  Number(utils.formatEther(frontDeskAllowance).toString()) <
                    Number(depositAmount) &&
                  'Approve'}
              </div>
            </div>
          </div>
          <div className="pt-6 w-full px-6 ">
            <div
              className="bg-white px-4 pt-2 pb-4 space-y-4 border"
              style={{
                borderColor: '#292d344d',
              }}
            >
              <div className="w-full flex flex-row justify-between pb-2">
                <div className="flex flex-col space-y-1 justify-between">
                  <div className="text-sm font-medium text-gray-300 flex flex-row space-x-2">
                    <div className="text-lg text-green-500">Step 2</div>
                  </div>
                  <div className="leading-none text-xs text-gray-400">
                    Stake LP tokens and earn FRN rewards.
                  </div>
                </div>
                <div className="flex flex-col space-y-1 justify-end items-end">
                  <div className="text-sm font-medium text-gray-300">APR</div>
                  <div className="leading-none text-2xl bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-green-400">
                    {branchData
                      ? Number(
                          BigNumber(branchData.rate)
                            .div(BigNumber(10).pow(BigNumber(18)))
                            .times(
                              BigNumber(branchData.totalUnderlying).div(
                                BigNumber(branchData.totalSupply),
                              ),
                            )
                            .plus(25),
                        ).toFixed(2) + '%'
                      : '0.00%'}
                  </div>
                </div>
              </div>

              <div className="relative w-full h-12 flex items-center justify-between ring-1 ring-brand-gray-primary rounded-lg transition-all duration-200 px-3">
                <input
                  type="text"
                  className="w-full h-full focus:outline-none placeholder-gray-400 font-medium pl-10 pr-9"
                  placeholder="0.00"
                  onChange={(e) => {
                    if (!isNaN(+e.target.value)) {
                      setStakeAmount(e.target.value)
                    }
                  }}
                  value={stakeAmount}
                />
                <img
                  src={Milady}
                  className="w-6 h-6 text-gray-400 border border-gray-300 rounded-full absolute top-1/2 left-3 -translate-y-1/2 pointer-events-none"
                />
                <img
                  src={BAILOUT_LOGO}
                  className="absolute h-4 bg-white border border-green-500 rounded-full"
                  style={{ transform: 'translate(11px, -6px)' }}
                />
                <button
                  type="button"
                  onClick={() => handleStakeMax()}
                  className="absolute bg-green-100 px-4 py-1 rounded-md top-1/2 right-3 -translate-y-1/2 "
                >
                  <span className="font-medium text-green-400 hover:text-green-500">
                    Max
                  </span>
                </button>
              </div>

              <div
                onClick={() => {
                  connectedAccount
                    ? stakeLpTokens(utils.parseEther(stakeAmount).toString())
                    : connectWallet()
                }}
                className=" leading-none text-md font-medium py-4 text-white text-center bg-gradient-to-r from-green-500 to-green-400 w-full rounded-lg cursor-pointer hover:from-green-400 hover:to-green-300"
              >
                Stake
              </div>
            </div>
          </div>
          {/* */}
        </div>

        <div className="col-span-1">
          <div className="w-full pt-4 flex flex-row justify-between">
            <div className="px-2 sm:px-6 flex flex-col">
              <h2 className="text-3xl leading-8 font-semibold tracking-tight text-gray-700 sm:text-3xl">
                Borrow
              </h2>
              <p className="mt-1 max-w-3xl mx-auto text-sm text-gray-400">
                Use your nft as collateral for a loan
              </p>
            </div>
          </div>

          <div className="pt-4 w-full px-6 ">
            <div
              className="bg-white px-4 pt-2 pb-4 space-y-4 border"
              style={{
                borderColor: '#292d344d',
              }}
            >
              <div className="w-full flex flex-row justify-between pb-2">
                <div className="flex flex-col space-y-1 justify-between">
                  <div className="text-sm font-medium text-gray-300 flex flex-row space-x-2">
                    <div className="text-lg text-green-500">Step 1</div>
                  </div>
                  <div className="leading-none text-xs text-gray-400">
                    Select nft to use as collateral.
                  </div>
                </div>
                <div className="flex flex-col space-y-1 justify-end items-end">
                  <div className="text-sm font-medium text-gray-300">
                    Branch
                  </div>
                  <div className="leading-none text-2xl bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-green-400">
                    MILADY
                  </div>
                </div>
              </div>

              <Dropdown
                isLoadingAssets={isLoadingAssets}
                nfts={nfts}
                connectedAccount={connectedAccount}
                connectWallet={connectWallet}
                setNftId={setNftId}
                selected={selectedNft}
                setSelected={setSelectedNft}
              />
              <div
                onClick={() => {
                  if (connectedAccount) {
                    if (!branchHasApproval && !isApprovingBranch) {
                      giveBranchApproval()
                    }
                  } else {
                    connectWallet()
                  }
                }}
                className={`h-12 max-h-12 leading-none text-md font-medium py-2 items-center flex border border-green-500 bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-green-400 text-center w-full rounded-lg ${
                  branchHasApproval ? '' : 'cursor-pointer'
                } `}
              >
                {isApprovingBranch && (
                  <div
                    className="w-full flex justify-center"
                    style={{ transform: 'translate(8px,-0px)' }}
                  >
                    <Spinner color="green" />
                  </div>
                )}
                {!isApprovingBranch && !branchHasApproval && (
                  <div className="w-full flex justify-center items-center space-x-1 text-green-400">
                    Approve
                  </div>
                )}
                {!isApprovingBranch && branchHasApproval && (
                  <div className="w-full flex justify-center items-center space-x-1 text-green-400">
                    <div className="cursor-default">Approved</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      style={{ transform: 'translateY(1px' }}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="pt-6 w-full px-6 ">
            <div
              className="bg-white px-4 pt-2 pb-4 space-y-4 border"
              style={{
                borderColor: '#292d344d',
              }}
            >
              <div className="w-full flex flex-row justify-between pb-2">
                <div className="flex flex-col space-y-1 justify-between">
                  <div className="text-sm font-medium text-gray-300 flex flex-row space-x-2">
                    <div className="text-lg text-green-500">Step 2</div>
                  </div>
                  <div className="leading-none text-xs text-gray-400">
                    Select amount of WETH to borrow.
                  </div>
                </div>
                <div className="flex flex-col space-y-1 justify-end items-end">
                  <div className="text-sm font-medium text-gray-300">
                    Variable Rate
                  </div>
                  <div className="leading-none text-2xl bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-green-400">
                    {branchData
                      ? Number(
                          BigNumber(branchData.rate).div(
                            BigNumber(10).pow(BigNumber(18)),
                          ),
                        ).toFixed(2) + '%'
                      : '0.00%'}
                  </div>
                </div>
              </div>

              <div className="relative w-full h-12 flex items-center justify-between ring-1 ring-brand-gray-primary rounded-lg transition-all duration-200 px-3">
                <input
                  type="text"
                  className="w-full h-full focus:outline-none placeholder-gray-400 font-medium pl-10 pr-9"
                  placeholder="0.00"
                  onChange={(e) => {
                    if (!isNaN(+e.target.value) && selectedNft !== '') {
                      setBorrowAmount(e.target.value)
                    }
                  }}
                  value={borrowAmount}
                />
                <img
                  src={WETH_LOGO}
                  className="w-6 h-6 text-gray-400 border border-gray-300 rounded-full absolute top-1/2 left-3 -translate-y-1/2 pointer-events-none"
                />
                <button
                  onClick={() => handleBorrowMax()}
                  type="button"
                  className="absolute bg-green-100 px-4 py-1 rounded-md top-1/2 right-3 -translate-y-1/2 "
                >
                  <span className="font-medium text-green-400 hover:text-green-500">
                    Max
                  </span>
                </button>
              </div>

              {renderBorrowWarning()}

              <div
                onClick={() => {
                  if (connectedAccount) {
                    if (!isBorrowing && selectedNft !== '') {
                      borrowFromBranch(nftId, utils.parseEther(borrowAmount))
                    }
                  } else {
                    connectWallet()
                  }
                }}
                className={`leading-none h-12 text-md font-medium py-4 border border-green-500 bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-green-400 text-center w-full rounded-lg ${
                  branchHasApproval
                    ? selectedNft !== ''
                      ? 'cursor-pointer'
                      : ''
                    : ''
                }`}
              >
                {isBorrowing && (
                  <div
                    className="w-full flex justify-center"
                    style={{ transform: 'translate(8px, -4px)' }}
                  >
                    <Spinner color="green" />
                  </div>
                )}
                {!isBorrowing && (
                  <div className="w-full flex justify-center items-center space-x-1 text-green-400">
                    Borrow
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* */}
        </div>
      </div>
    </div>
  )
}
