import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from 'react-router-dom'
import WalletConnectProviderMatic from '@maticnetwork/walletconnect-provider'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { connectWallet } from './helpers.js'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import Web3 from 'web3'
import { utils } from 'ethers'

import Liquidity from './Liquidity'
import Deposit from './Deposit'
import StakingBox from './StakingBox'
import Loans from './Loans'
import Stake from './Stake'
import NftBox from './NftBox'

import ConnectModal from './modals/ConnectModal'
import WrongChainModal from './modals/WrongChainModal'
import ApproveTokenModal from './modals/ApproveTokenModal'

import {
  getBranch,
  getLoansByOwner,
  getNfts,
  getWeth,
  getFrn,
  getBranchNftApproval,
  giveBranchNftApproval,
  getBranchWethAllowance,
  approveBranchWeth,
  makeBranchDeposit,
  borrowFromBranch,
  repayLoan,
  repackageLoan,
  getFrontdeskWethAllowance,
  approveFrontdesk,
  getBranchLp,
  mintDevWeth,
  mintDevNft,
  stakeLpToken,
  getStakedLpToken,
  stakeFRN,
  getStakedFRN,
  getLpTokenStakingAllowance,
  approveLpTokenStaking,
} from './utils/utils'

TimeAgo.addDefaultLocale(en)

const provider = new WalletConnectProvider({
  rpc: {
    1: 'https://eth-mainnet.alchemyapi.io/v2/yBM2MKBWBPv5gmGQusTn6z2knL2F9eEZ',
    //80001: 'https://polygon-mumbai.g.alchemy.com/v2/4If-yXORaE376qrsnGQIy7JRyJ94lh7e',
  },
})

const Main = () => {
  const isProd =
    window.location.hostname.indexOf('localhost') > -1 ||
    window.location.hostname.indexOf('mumbai') > -1
      ? false
      : true

  // Modal State Variables
  const [isWrongChainModalOpen, setIsWrongChainModalOpen] = useState(false)
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false)
  const [isApproveTokenModalOpen, setIsApproveTokenModalOpen] = useState(false)

  const [web3Action, setWeb3Action] = useState({
    isLoading: false,
    isMining: false,
    name: '',
    type: '',
    contract: '',
  })

  const [isConnecting, setIsConnecting] = useState(false)
  const [connectedAccount, setConnectedAccount] = useState('')
  const [isConnectingWalletConnect, setIsConnectingWalletConnect] = useState(
    false,
  )
  const [walletConnect, setWalletConnect] = useState(false)

  const [isLoadingAssets, setIsLoadingAssets] = useState(true)
  const [assetLoadError, setAssetLoadError] = useState(false)
  const [nfts, setNfts] = useState([])
  const [weth, setWeth] = useState('0')
  const [frn, setFrn] = useState('0')
  const [frns, setFrns] = useState('0')
  const [branchLp, setBranchLp] = useState('0')
  const [stakedBranchLp, setStakedBranchLp] = useState('0')

  const [branchData, setBranchData] = useState('')

  const [isApprovingFrontDesk, setIsApprovingFrontDesk] = useState(false)
  const [frontDeskAllowance, setFrontDeskAllowance] = useState('0')

  const [isApprovingBranchWeth, setIsApprovingBranchWeth] = useState(false)
  const [branchWethAllowance, setBranchWethAllowance] = useState('0')

  const [isApprovingBranch, setIsApprovingBranch] = useState(false)
  const [branchHasApproval, setBranchHasApproval] = useState(false)

  const [isMakingDeposit, setIsMakingDeposit] = useState(false)
  const [isStakingLpToken, setIsStakingLpToken] = useState(false)
  const [isBorrowing, setIsBorrowing] = useState(false)

  const [depositAmount, setDepositAmount] = useState('')
  const [stakeAmount, setStakeAmount] = useState('')
  const [frnAmount, setFrnAmount] = useState('')

  const [selectedNft, setSelectedNft] = useState('')
  const [borrowAmount, setBorrowAmount] = useState('')

  const [isLoansLoading, setIsLoansLoading] = useState(true)
  const [userLoans, setUserLoans] = useState([])

  const [activeLoan, setActiveLoan] = useState('')
  const [activeAction, setActiveAction] = useState('')
  const [isRepayingLoan, setIsRepayingLoan] = useState(false)
  const [isRepackingLoan, setIsRepackagingLoan] = useState(false)

  useEffect(async () => {
    getBranch(isProd).then((result) => setBranchData(result))

    if (typeof window.ethereum !== 'undefined') {
      // Shows 'change_chain' popup if user is connect to wrong chain.
      const chainId = await window.ethereum.request({ method: 'eth_chainId' })
      if (isProd === false && chainId !== '0x13881')
        setIsWrongChainModalOpen(true)

      // Reloads page on account or chain change.
      window.ethereum.on('accountsChanged', () => window.location.reload())
      window.ethereum.on('chainChanged', () => window.location.reload())
    }
  }, [])

  useEffect(async () => {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: 'eth_accounts',
      })
      if (accounts.length > 0) setConnectedAccount(accounts[0])
    }
  }, [window.ethereum])

  useEffect(() => {
    console.log('connectedAccount', connectedAccount)
    if (connectedAccount.length > 0) {
      Promise.all([
        getNfts(connectedAccount, isProd),
        getWeth(connectedAccount, isProd),
        getFrn(connectedAccount, isProd),
        getBranchNftApproval(connectedAccount, isProd),
        getBranch(isProd),
        getFrontdeskWethAllowance(connectedAccount, isProd),
        getBranchLp(connectedAccount, isProd),
        getBranchWethAllowance(connectedAccount, isProd),
        getStakedLpToken(connectedAccount, isProd),
      ])
        .then((assets) => {
          setNfts(assets[0])
          setWeth(assets[1])
          setFrn(assets[2])
          setBranchHasApproval(assets[3])
          setBranchData(assets[4])
          setFrontDeskAllowance(assets[5])
          setBranchLp(assets[6])
          setBranchWethAllowance(assets[7])
          setStakedBranchLp(assets[8])
          setIsLoadingAssets(false)

          console.log(assets)
        })
        .catch((error) => {
          console.log(error)
          setAssetLoadError(true)
          setIsLoadingAssets(false)
        })
    }
  }, [connectedAccount])

  useEffect(() => {
    if (connectedAccount) {
      getLoansByOwner(connectedAccount, isProd)
        .then((loans) => {
          setUserLoans(loans)
          setIsLoansLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setIsLoansLoading(false)
        })
    }
  }, [connectedAccount])

  const handleSwitchChains = () => {
    if (walletConnect) {
    } else {
      try {
        window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainName: 'Mumbai',
              chainId: '0x13881',
              nativeCurrency: { name: 'Matic', decimals: 18, symbol: 'MATIC' },
              rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
            },
          ],
        })
        window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: isProd === false ? '0x13881' : '0x1' }],
        })
      } catch (err) {
        if (err.code === 4902 || err.code === -32603) {
          window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: 'Mumbai',
                chainId: '0x13881',
                nativeCurrency: {
                  name: 'Matic',
                  decimals: 18,
                  symbol: 'MATIC',
                },
                rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
              },
            ],
          })
        }
      }
    }
  }

  const handleApproveFrontDesk = () => {
    setIsApprovingFrontDesk(true)
    approveFrontdesk(connectedAccount, isProd, {
      isWalletConnect: walletConnect,
      provider: provider,
    })
      .then((result) => {
        setIsApprovingFrontDesk(false)
        setFrontDeskAllowance('1000000000000000000000000')
      })
      .catch((error) => {
        setIsApprovingFrontDesk(false)
      })
  }

  const handleMakeBranchDeposit = (amount) => {
    setIsMakingDeposit(true)
    makeBranchDeposit(connectedAccount, amount, isProd, {
      isWalletConnect: walletConnect,
      provider: provider,
    })
      .then((result) => {
        Promise.all([
          getWeth(connectedAccount, isProd),
          getBranchLp(connectedAccount, isProd),
          getBranch(isProd),
        ])
          .then((assets) => {
            setWeth(assets[0])
            setBranchLp(assets[1])
            setBranchData(assets[2])
            setDepositAmount('')

            setIsMakingDeposit(false)
          })
          .catch((error) => {
            setIsMakingDeposit(false)
          })
      })
      .catch((error) => {
        console.log(error)
        setIsMakingDeposit(false)
      })
  }

  const handleStakeBranchLpToken = (amount) => {
    setIsStakingLpToken(true)

    getLpTokenStakingAllowance(connectedAccount, isProd)
      .then((result) => {
        console.log('lp token staking allowance', result)
        if (
          Number(utils.formatEther(result) < Number(utils.formatEther(amount)))
        ) {
          approveLpTokenStaking(connectedAccount, isProd, {
            isWalletConnect: walletConnect,
            provider: provider,
          })
        } else {
          stakeLpToken(connectedAccount, amount, isProd, {
            isWalletConnect: walletConnect,
            provider: provider,
          })
            .then((result) => {
              Promise.all([
                getWeth(connectedAccount, isProd),
                getBranchLp(connectedAccount, isProd),
                getBranch(isProd),
                getStakedLpToken(connectedAccount, isProd),
              ])
                .then((assets) => {
                  setWeth(assets[0])
                  setBranchLp(assets[1])
                  setBranchData(assets[2])
                  setStakedBranchLp(assets[3])
                  setDepositAmount('')

                  setIsStakingLpToken(false)
                })
                .catch((error) => {
                  setIsStakingLpToken(false)
                })
            })
            .catch((error) => {
              console.log(error)
              setIsStakingLpToken(false)
            })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleGiveBranchApproval = () => {
    setIsApprovingBranch(true)
    giveBranchNftApproval(connectedAccount, isProd, {
      isWalletConnect: walletConnect,
      provider: provider,
    })
      .then((result) => {
        setIsApprovingBranch(false)
        setBranchHasApproval(true)
      })
      .catch((error) => {
        setIsApprovingBranch(false)
      })
  }

  const handleGiveBranchWethApproval = (loanId, type) => {
    setIsApprovingBranchWeth(true)
    setActiveLoan(loanId)
    setActiveAction(type)

    console.log(walletConnect)
    approveBranchWeth(connectedAccount, isProd, {
      isWalletConnect: walletConnect,
      provider: provider,
    })
      .then((result) => {
        setActiveLoan('')
        setIsApprovingBranchWeth(false)
        setBranchWethAllowance('1000000000000000000000000')
        setActiveAction('')
      })
      .catch((error) => {
        setActiveLoan('')
        setIsApprovingBranchWeth(false)
        setActiveAction('')
      })
  }

  const handleConnectWallet = () => {
    setIsConnectModalOpen(true)
  }

  const connectMetamaskWallet = () => {
    setIsConnecting(true)

    connectWallet()
      .then((wallet) => {
        setConnectedAccount(wallet)
        setIsConnecting(false)
        setIsConnectModalOpen(false)
      })
      .catch((error) => {
        setIsConnecting(false)
      })
  }

  const connectWalletConnect = async () => {
    setIsConnectingWalletConnect(true)
    try {
      await provider.enable()
      const web3 = new Web3(provider)
      const accounts = await web3.eth.getAccounts()

      console.log(accounts)
      setConnectedAccount(accounts[0])

      setWalletConnect(true)
      setIsConnectingWalletConnect(false)
      setIsConnectModalOpen(false)
    } catch (error) {
      console.log(error)
      setIsConnectingWalletConnect(false)
    }
  }

  const disconnectWalletConnect = async () => {
    try {
      await provider.disconnect()
      setConnectedAccount('')
    } catch (error) {
      console.log(error)
    }
  }

  const handleDevWethMint = () => {
    console.log(connectedAccount)
    mintDevWeth(connectedAccount, {
      isUsingWalletConnect: walletConnect,
      provider: provider,
    }).then((result) => {
      Promise.all([getWeth(connectedAccount, isProd)]).then((assets) => {
        setWeth(assets[0])
      })
    })
  }

  const handleDevNftMint = () => {
    mintDevNft(connectedAccount).then((result) => {
      Promise.all([getNfts(connectedAccount, isProd)])
        .then((assets) => {
          setNfts(assets[0])
        })
        .catch((error) => {
          console.log(error)
        })
    })
  }

  const handleBorrowFromBranch = (nftId, amount) => {
    console.log(nftId, amount)
    setIsBorrowing(true)
    borrowFromBranch(connectedAccount, nftId, amount, isProd, {
      isWalletConnect: walletConnect,
      provider: provider,
    })
      .then((result) => {
        setIsLoansLoading(true)
        Promise.all([
          getNfts(connectedAccount, isProd),
          getWeth(connectedAccount, isProd),
          getBranch(isProd),
          getLoansByOwner(connectedAccount, isProd),
        ])
          .then((assets) => {
            setNfts(assets[0])
            setWeth(assets[1])
            setBranchData(assets[2])
            setUserLoans(assets[3])
            setIsBorrowing(false)
            setSelectedNft('')
            setBorrowAmount('')
            setIsLoansLoading(false)
          })
          .catch((error) => {
            setIsBorrowing(false)
          })
      })
      .catch((error) => {
        console.log(error)
        setIsBorrowing(false)
      })
  }

  const handleRepayLoan = (loanId) => {
    setActiveLoan(loanId)
    setIsRepayingLoan(true)
    repayLoan(connectedAccount, loanId, isProd, {
      isWalletConnect: walletConnect,
      provider: provider,
    })
      .then((result) => {
        Promise.all([
          getNfts(connectedAccount, isProd),
          getWeth(connectedAccount, isProd),
          getBranch(isProd),
          getLoansByOwner(connectedAccount, isProd),
        ]).then((assets) => {
          setNfts(assets[0])
          setWeth(assets[1])
          setBranchData(assets[2])
          setUserLoans(assets[3])
          setActiveLoan('')
          setIsRepayingLoan(false)
        })
      })
      .catch((error) => {
        setActiveLoan('')
        setIsRepayingLoan(false)
      })
  }

  const handleRepackageLoan = (loanId) => {
    setActiveLoan(loanId)
    setIsRepackagingLoan(true)

    repackageLoan(connectedAccount, loanId, isProd, {
      isWalletConnect: walletConnect,
      provider: provider,
    })
      .then((result) => {
        getLoansByOwner(connectedAccount, isProd).then((loans) => {
          setActiveLoan('')
          setIsRepackagingLoan(false)
          setUserLoans(loans)
        })
      })
      .catch((error) => {
        console.log(error)
        setActiveLoan('')
        setIsRepackagingLoan(false)
      })
  }

  const PageHeader = () => {
    let location = useLocation()
    let selectedPage = location.pathname.split('/')[1]

    return (
      <div
        className=" w-full flex justify-center px-4 xl:px-6 xl:max-w-7xl "
        style={{
          borderBottom: '#292d344d solid 1px',
          paddingTop: '11px',
          paddingBottom: '11px',
        }}
      >
        <div className="w-full flex items-center justify-between">
          <Link
            to="/"
            className=" leading-none text-2xl bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-green-400"
            style={{ fontFamily: 'RadwaveRegular', marginTop: '5px' }}
          >
            Bailout Protocol
            {/*
              <div className="inline-flex pl-2 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-500 opacity-80">
                - Mumbai
              </div>
        */}
          </Link>
          <div className="flex space-x-2 sm:space-x-6">
            <div className="flex space-x-2 sm:space-x-6 text-gray-500 font-medium text-sm">
              <Link
                to="/"
                className="hidden sm:flex items-center sm:space-x-2 cursor-pointer hover:text-gray-400"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-5 w-5 ${
                    selectedPage === '' ? 'text-green-500' : ''
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
                <div
                  className={`${selectedPage === '' ? 'text-green-500' : ''}`}
                >
                  Dashboard
                </div>
              </Link>
              {/*
              <Link
                to="/deposit"
                className="flex items-center space-x-2 cursor-pointer hover:text-gray-400"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-5 w-5 ${
                    selectedPage === 'deposit' ? 'text-green-500' : ''
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
                  />
                </svg>
                <div
                  className={`${
                    selectedPage === 'deposit' ? 'text-green-500' : ''
                  }`}
                >
                  Deposit
                </div>
              </Link>
                */}
              {connectedAccount && (
                <Link
                  to="loans"
                  className="hidden sm:flex items-center sm:space-x-2 cursor-pointer hover:text-gray-400"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`h-5 w-5 ${
                      selectedPage === 'loans' ? 'text-green-500' : ''
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <div
                    className={`${
                      selectedPage === 'loans' ? 'text-green-500' : ''
                    }`}
                  >
                    Loans
                  </div>
                </Link>
              )}
              {!connectedAccount && (
                <div className="hidden sm:flex items-center sm:space-x-2 cursor-default opacity-20 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`h-5 w-5 ${
                      selectedPage === 'loans' ? 'text-green-500' : ''
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <div
                    className={`${
                      selectedPage === 'loans' ? 'text-green-500' : ''
                    }`}
                  >
                    Loans
                  </div>
                </div>
              )}
            </div>
            <button
              onClick={(e) => {
                e.preventDefault()
                if (!connectedAccount) {
                  handleConnectWallet()
                } else {
                  disconnectWalletConnect()
                }
              }}
              type="button"
              className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium rounded-md text-white bg-gradient-to-r from-green-500 to-green-400  hover:to-green-500 focus:outline-none"
            >
              {connectedAccount
                ? connectedAccount.slice(0, 7) + '...'
                : 'Connect'}
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className="min-h-full "
      style={{
        //backgroundImage: `url(${TropicalBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'Cover',
      }}
    >
      <WrongChainModal
        open={isWrongChainModalOpen}
        connectWallet={connectMetamaskWallet}
        connectWalletConnect={connectWalletConnect}
        switchChain={handleSwitchChains}
        isDev={isProd === false}
      />

      {!connectedAccount && (
        <ConnectModal
          open={isConnectModalOpen}
          setOpen={setIsConnectModalOpen}
          connectWallet={connectMetamaskWallet}
          connectWalletConnect={connectWalletConnect}
          isConnecting={isConnecting}
          isConnectingWalletConnect={isConnectingWalletConnect}
        />
      )}

      <ApproveTokenModal open={isApproveTokenModalOpen} />

      <main className="w-full sm:max-w-3xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl">
        <BrowserRouter>
          <PageHeader />
          <Routes>
            <Route
              path="/"
              element={
                <div className=" w-full grid grid-cols-1 xl:max-w-7xl xl:grid-flow-col-dense xl:grid-cols-3">
                  <section className="xl:col-start-1 xl:col-span-2">
                    <Deposit
                      isLoadingAssets={isLoadingAssets}
                      isApprovingBranch={isApprovingBranch}
                      giveBranchApproval={handleGiveBranchApproval}
                      branchHasApproval={branchHasApproval}
                      isApprovingFrontDesk={isApprovingFrontDesk}
                      approveFrontDesk={handleApproveFrontDesk}
                      frontDeskAllowance={frontDeskAllowance}
                      nfts={nfts}
                      weth={weth}
                      frn={frn}
                      connectedAccount={connectedAccount}
                      connectWallet={handleConnectWallet}
                      isDepositing={isMakingDeposit}
                      makeDeposit={handleMakeBranchDeposit}
                      isBorrowing={isBorrowing}
                      borrowFromBranch={handleBorrowFromBranch}
                      branchData={branchData}
                      depositAmount={depositAmount}
                      setDepositAmount={setDepositAmount}
                      stakeAmount={stakeAmount}
                      setStakeAmount={setStakeAmount}
                      selectedNft={selectedNft}
                      setSelectedNft={setSelectedNft}
                      borrowAmount={borrowAmount}
                      setBorrowAmount={setBorrowAmount}
                      branchLp={branchLp}
                      stakeLpTokens={handleStakeBranchLpToken}
                      stakedBranchLp={stakedBranchLp}
                    />
                  </section>
                  <section
                    className="space-y-4 xl:col-start-3 xl:col-span-1 border-l"
                    style={{ borderColor: '#292d344d' }}
                  >
                    <NftBox
                      isLoadingAssets={isLoadingAssets}
                      assetLoadError={assetLoadError}
                      weth={weth}
                      frn={frn}
                      branchLp={branchLp}
                      stakedBranchLp={stakedBranchLp}
                      connectedAccount={connectedAccount}
                      connectWallet={handleConnectWallet}
                      mintDevWeth={handleDevWethMint}
                      mintDevNft={handleDevNftMint}
                      isProd={isProd}
                    />
                    <StakingBox
                      isLoadingAssets={isLoadingAssets}
                      connectedAccount={connectedAccount}
                      connectWallet={handleConnectWallet}
                    />
                  </section>
                </div>
              }
            />

            <Route
              path="/loans"
              element={
                <div className="w-full grid grid-cols-1 xl:max-w-7xl xl:grid-flow-col-dense xl:grid-cols-3">
                  <section className="xl:col-start-1 xl:col-span-2">
                    <Loans
                      weth={weth}
                      connectedAccount={connectedAccount}
                      approveBranchWeth={handleGiveBranchWethApproval}
                      isApprovingBranchWeth={isApprovingBranchWeth}
                      branchWethAllowance={branchWethAllowance}
                      loans={userLoans}
                      repayLoan={handleRepayLoan}
                      repackageLoan={handleRepackageLoan}
                      activeLoan={activeLoan}
                      isRepayingLoan={isRepayingLoan}
                      isRepackingLoan={isRepackingLoan}
                      activeAction={activeAction}
                      isLoansLoading={isLoansLoading}
                    />
                  </section>
                  <section
                    className="space-y-4 xl:col-start-3 xl:col-span-1 border-l"
                    style={{ borderColor: '#292d344d' }}
                  >
                    {' '}
                    <NftBox
                      isLoadingAssets={isLoadingAssets}
                      assetLoadError={assetLoadError}
                      weth={weth}
                      frn={frn}
                      branchLp={branchLp}
                      stakedBranchLp={stakedBranchLp}
                      connectedAccount={connectedAccount}
                      connectWallet={handleConnectWallet}
                      mintDevWeth={handleDevWethMint}
                      mintDevNft={handleDevNftMint}
                      isProd={isProd}
                    />
                    <StakingBox
                      isLoadingAssets={isLoadingAssets}
                      connectedAccount={connectedAccount}
                      connectWallet={handleConnectWallet}
                    />
                  </section>
                </div>
              }
            />

            <Route
              path="/overview"
              element={
                <div className="max-w-3xl mx-auto grid grid-cols-1 gap-6 xl:max-w-7xl xl:grid-flow-col-dense xl:grid-cols-3">
                  <section className="lg:pl-6 lg:pb-6 xl:col-start-1 xl:col-span-2">
                    <Liquidity />
                  </section>
                  <section
                    className="space-y-4 xl:col-start-3 xl:col-span-1 border-l"
                    style={{ borderColor: '#292d344d' }}
                  >
                    {' '}
                    <NftBox
                      isLoadingAssets={isLoadingAssets}
                      assetLoadError={assetLoadError}
                      weth={weth}
                      frn={frn}
                      branchLp={branchLp}
                      stakedBranchLp={stakedBranchLp}
                      connectedAccount={connectedAccount}
                      connectWallet={handleConnectWallet}
                      mintDevWeth={handleDevWethMint}
                      mintDevNft={handleDevNftMint}
                      isProd={isProd}
                    />
                    <StakingBox
                      isLoadingAssets={isLoadingAssets}
                      connectedAccount={connectedAccount}
                      connectWallet={handleConnectWallet}
                    />
                  </section>
                </div>
              }
            />
          </Routes>
        </BrowserRouter>
      </main>
    </div>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById('root'),
)
