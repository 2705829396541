import Web3 from 'web3'
import Branch_ABI from '../assets/abis/Branch'
import FrontDesk_ABI from '../assets/abis/FrontDesk'
import Lehman_ABI from '../assets/abis/Lehman'
import USD_ABI from '../assets/abis/USD'
import ERC20_ABI from '../assets/abis/ERC20'
import ERC721_ABI from '../assets/abis/ERC721'
import { createAlchemyWeb3 } from '@alch/alchemy-web3'
import { utils } from 'ethers'

import AccountingERC20 from '../assets/mumbai/AccountingERC20.json'
import Branch from '../assets/mumbai/Branch.json'
import ERC20Staking from '../assets/mumbai/ERC20Staking.json'
import FRN from '../assets/mumbai/FRN.json'
import FRNs from '../assets/mumbai/FRNs.json'
import FrontDesk from '../assets/mumbai/FrontDesk.json'
import Lehman from '../assets/mumbai/Lehman.json'

const ALCHEMY_PROD = 'yBM2MKBWBPv5gmGQusTn6z2knL2F9eEZ'
const ALCHEMY_DEV = '4If-yXORaE376qrsnGQIy7JRyJ94lh7e'

const LEHMAN_PROD = '0x32cB6aE94cBda6146Bd1797428bA1b65869b9C31'
const LEHMAN_DEV = '0xA60A930A20157fdA4289A56AE1053a0Be473FD21'

const FRN_ADDRESS_PROD = '0x1FA99F9685870eca96fb5660BB0672bd00a1F666'
const FRN_ADDRESS_DEV = '0xb968D132651FD666D9b8b425dE6b693ea903c124'

const MILADY_ADDRESS_PROD = '0x5af0d9827e0c53e4799bb226655a1de152a425a5'
const MILADY_ADDRESS_DEV = '0x10212f072d215876f6be4d3638fd2c867fa6d562'

const WETH_ADDRESS_PROD = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
const WETH_ADDRESS_DEV = '0x5fD4ce273F437178a0dd603E93391e07d17eBaD1'

const LAUNCH_BRANCH_ADDRESS_PROD = '0xc48cb41Fc7240a76D3D35558fd707E45Cc5C2eCA'
const LAUNCH_BRANCH_ADDRESS_DEV = '0xDcB7Cf4289584f31904052fE7658082FA79BeFBa'

const LAUNCH_BRANCH_LP_ADDRESS_PROD =
  '0x2ad269f65CdBF5f54455d4c944bA109B18201B96'
const LAUNCH_BRANCH_LP_ADDRESS_DEV =
  '0x1710fae08c382e1b2d5b63832bc50f5b4e1def28'

const FRONTDESK_ADDRESS_PROD = '0x4066256AaeE19F9ed3Cdd6deF09E1220675b4979'
const FRONTDESK_ADDRESS_DEV = '0x82732FAef986f2d96724E9332f5AB5C398266EbA'

const ERC20STAKING_ADDRESS_PROD = '0x864a46e3AEA43368923CE47272d2e9D4C24f2F25'
const ERC20STAKING_ADDRESS_DEV = '0xf18B20137e206930F83dcd7246Dc9cEA5bF459C7'

const getNfts = (owner, isProd = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      const web3 = createAlchemyWeb3(
        `https://${
          isProd ? 'eth-mainnet.alchemyapi.io' : 'polygon-mumbai.g.alchemy.com'
        }/v2/${isProd ? ALCHEMY_PROD : ALCHEMY_DEV}`,
      )

      const rawNfts = await web3.alchemy.getNfts({
        owner: owner,
        contractAddresses: [isProd ? MILADY_ADDRESS_PROD : MILADY_ADDRESS_DEV],
      })

      let nfts = []
      rawNfts.ownedNfts.forEach((rawNft) => {
        nfts.push({
          id:
            rawNft.id.tokenId.indexOf('0x') === 0
              ? web3.utils.hexToNumber(rawNft.id.tokenId)
              : rawNft.id.tokenId,
          image: rawNft.media[0].gateway,
        })
      })

      resolve(nfts)
    } catch (error) {
      console.log(error)
      reject(error)
    }
  })
}

const getWeth = (owner, isProd = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      const web3 = createAlchemyWeb3(
        `https://${
          isProd ? 'eth-mainnet.alchemyapi.io' : 'polygon-mumbai.g.alchemy.com'
        }/v2/${isProd ? ALCHEMY_PROD : ALCHEMY_DEV}`,
      )

      const WETH = new web3.eth.Contract(
        ERC20_ABI,
        isProd ? WETH_ADDRESS_PROD : WETH_ADDRESS_DEV,
      )

      const balance = await WETH.methods.balanceOf(owner).call()

      resolve(balance)
    } catch (error) {
      reject(error)
    }
  })
}

const getFrn = (owner, isProd = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      const web3 = createAlchemyWeb3(
        `https://${
          isProd ? 'eth-mainnet.alchemyapi.io' : 'polygon-mumbai.g.alchemy.com'
        }/v2/${isProd ? ALCHEMY_PROD : ALCHEMY_DEV}`,
      )

      const frn = new web3.eth.Contract(
        ERC20_ABI,
        isProd ? FRN_ADDRESS_PROD : FRN_ADDRESS_DEV,
      )

      const balance = await frn.methods.balanceOf(owner).call()

      resolve(balance)
    } catch (error) {
      reject(error)
    }
  })
}

const getBranchLp = (owner, isProd = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      const web3 = createAlchemyWeb3(
        `https://${
          isProd ? 'eth-mainnet.alchemyapi.io' : 'polygon-mumbai.g.alchemy.com'
        }/v2/${isProd ? ALCHEMY_PROD : ALCHEMY_DEV}`,
      )

      const lehman = new web3.eth.Contract(
        Lehman.abi,
        isProd ? LEHMAN_PROD : LEHMAN_DEV,
      )

      let branch = await lehman.methods
        .branches(
          isProd ? MILADY_ADDRESS_PROD : MILADY_ADDRESS_DEV,
          isProd ? WETH_ADDRESS_PROD : WETH_ADDRESS_DEV,
          0,
        )
        .call()

      const branchLp = new web3.eth.Contract(
        ERC20_ABI,
        isProd ? LAUNCH_BRANCH_LP_ADDRESS_PROD : LAUNCH_BRANCH_LP_ADDRESS_DEV,
      )

      const balance = await branchLp.methods.balanceOf(owner).call()

      resolve(balance)
    } catch (error) {
      reject(error)
    }
  })
}

const stakeLpToken = (owner, amount, isProd = false, walletConnect) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (window.ethereum || walletConnect.isWalletConnect) {
        const web3 = new Web3(
          walletConnect.isWalletConnect
            ? walletConnect.provider
            : window.ethereum,
        )
        const stakingPool = new web3.eth.Contract(
          ERC20Staking.abi,
          isProd ? ERC20STAKING_ADDRESS_PROD : ERC20STAKING_ADDRESS_DEV,
        )

        await stakingPool.methods
          .deposit(
            isProd
              ? LAUNCH_BRANCH_LP_ADDRESS_PROD
              : LAUNCH_BRANCH_LP_ADDRESS_DEV,
            amount.toString(),
            owner,
          )
          .send({ from: owner, gas: '150000' })

        resolve(true)
      } else {
        reject('No injected web3 provider')
      }
    } catch (error) {
      reject(error)
    }
  })
}

const getStakedLpToken = (owner, isProd = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      const web3 = createAlchemyWeb3(
        `https://${
          isProd ? 'eth-mainnet.alchemyapi.io' : 'polygon-mumbai.g.alchemy.com'
        }/v2/${isProd ? ALCHEMY_PROD : ALCHEMY_DEV}`,
      )

      const lehman = new web3.eth.Contract(
        Lehman.abi,
        isProd ? LEHMAN_PROD : LEHMAN_DEV,
      )

      const stakingPool = new web3.eth.Contract(
        ERC20Staking.abi,
        isProd ? ERC20STAKING_ADDRESS_PROD : ERC20STAKING_ADDRESS_DEV,
      )

      const balance = await stakingPool.methods
        .userInfo(
          isProd ? LAUNCH_BRANCH_LP_ADDRESS_PROD : LAUNCH_BRANCH_LP_ADDRESS_DEV,
          owner,
        )
        .call()

      console.log(balance.rewardDebt)
      resolve(balance.amount)
    } catch (error) {
      console.log(error)
      reject(error)
    }
  })
}

const getLpTokenStakingAllowance = (owner, isProd = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      const web3 = createAlchemyWeb3(
        `https://${
          isProd ? 'eth-mainnet.alchemyapi.io' : 'polygon-mumbai.g.alchemy.com'
        }/v2/${isProd ? ALCHEMY_PROD : ALCHEMY_DEV}`,
      )
      const branchLp = new web3.eth.Contract(
        ERC20_ABI,
        isProd ? LAUNCH_BRANCH_LP_ADDRESS_PROD : LAUNCH_BRANCH_LP_ADDRESS_DEV,
      )

      const allowance = await branchLp.methods
        .allowance(
          owner,
          isProd ? ERC20STAKING_ADDRESS_PROD : ERC20STAKING_ADDRESS_DEV,
        )
        .call()
      resolve(allowance)
    } catch (error) {
      reject(error)
    }
  })
}

const approveLpTokenStaking = (owner, isProd = false, walletConnect) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (window.ethereum || walletConnect.isWalletConnect) {
        const web3 = new Web3(
          walletConnect.isWalletConnect
            ? walletConnect.provider
            : window.ethereum,
        )
        const branchLp = new web3.eth.Contract(
          ERC20_ABI,
          isProd ? LAUNCH_BRANCH_LP_ADDRESS_PROD : LAUNCH_BRANCH_LP_ADDRESS_DEV,
        )
        const allowance = await branchLp.methods
          .approve(
            isProd ? ERC20STAKING_ADDRESS_PROD : ERC20STAKING_ADDRESS_DEV,
            '1000000000000000000000000',
          )
          .send({ from: owner })
        resolve(true)
      } else {
        reject('No injected web3')
      }
    } catch (error) {
      reject(error)
    }
  })
}

const stakeFRN = (owner, amount, isProd = false, walletConnect) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (window.ethereum || walletConnect.isWalletConnect) {
        const web3 = new Web3(
          walletConnect.isWalletConnect
            ? walletConnect.provider
            : window.ethereum,
        )
        const stakingPool = new web3.eth.Contract(
          ERC20Staking.abi,
          isProd ? ERC20STAKING_ADDRESS_PROD : ERC20STAKING_ADDRESS_DEV,
        )

        await stakingPool.methods
          .deposit(isProd ? FRN_ADDRESS_PROD : FRN_ADDRESS_DEV, amount, owner)
          .send({ from: owner })

        resolve(true)
      } else {
        reject('No injected web3 provider')
      }
    } catch (error) {
      reject(error)
    }
  })
}

const getStakedFRN = (owner, isProd = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      const web3 = createAlchemyWeb3(
        `https://${
          isProd ? 'eth-mainnet.alchemyapi.io' : 'polygon-mumbai.g.alchemy.com'
        }/v2/${isProd ? ALCHEMY_PROD : ALCHEMY_DEV}`,
      )

      const stakingPool = new web3.eth.Contract(
        ERC20Staking.abi,
        isProd ? ERC20STAKING_ADDRESS_PROD : ERC20STAKING_ADDRESS_DEV,
      )

      const balance = await stakingPool.methods
        .userInfo(isProd ? FRN_ADDRESS_PROD : FRN_ADDRESS_DEV, owner)
        .call()

      resolve(balance)
    } catch (error) {
      reject(error)
    }
  })
}

const getBranchNftApproval = (owner, isProd = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      const web3 = createAlchemyWeb3(
        `https://${
          isProd ? 'eth-mainnet.alchemyapi.io' : 'polygon-mumbai.g.alchemy.com'
        }/v2/${isProd ? ALCHEMY_PROD : ALCHEMY_DEV}`,
      )

      const lehman = new web3.eth.Contract(
        Lehman.abi,
        isProd ? LEHMAN_PROD : LEHMAN_DEV,
      )

      const nft = new web3.eth.Contract(
        ERC721_ABI,
        isProd ? MILADY_ADDRESS_PROD : MILADY_ADDRESS_DEV,
      )

      const hasOperatorApproval = await nft.methods
        .isApprovedForAll(
          owner,
          isProd ? LAUNCH_BRANCH_ADDRESS_PROD : LAUNCH_BRANCH_ADDRESS_DEV,
        )
        .call()

      resolve(hasOperatorApproval)
    } catch (error) {
      reject(error)
    }
  })
}

const giveBranchNftApproval = (owner, isProd = false, walletConnect) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (window.ethereum || walletConnect.isWalletConnect) {
        const web3 = new Web3(
          walletConnect.isWalletConnect
            ? walletConnect.provider
            : window.ethereum,
        )

        const nft = new web3.eth.Contract(
          ERC721_ABI,
          isProd ? MILADY_ADDRESS_PROD : MILADY_ADDRESS_DEV,
        )

        const lehman = new web3.eth.Contract(
          Lehman.abi,
          isProd ? LEHMAN_PROD : LEHMAN_DEV,
        )

        let branch = await lehman.methods
          .branches(
            isProd ? MILADY_ADDRESS_PROD : MILADY_ADDRESS_DEV,
            isProd ? WETH_ADDRESS_PROD : WETH_ADDRESS_DEV,
            0,
          )
          .call()

        await nft.methods.setApprovalForAll(branch, true).send({ from: owner })

        resolve(true)
      } else {
        reject('No injected web3')
      }
    } catch (error) {
      reject(error)
    }
  })
}

const getFrontdeskWethAllowance = (owner, isProd = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      const web3 = createAlchemyWeb3(
        `https://${
          isProd ? 'eth-mainnet.alchemyapi.io' : 'polygon-mumbai.g.alchemy.com'
        }/v2/${isProd ? ALCHEMY_PROD : ALCHEMY_DEV}`,
      )

      const WETH = new web3.eth.Contract(
        ERC20_ABI,
        isProd ? WETH_ADDRESS_PROD : WETH_ADDRESS_DEV,
      )

      const allowance = await WETH.methods
        .allowance(
          owner,
          isProd ? FRONTDESK_ADDRESS_PROD : FRONTDESK_ADDRESS_DEV,
        )
        .call()
      resolve(allowance)
    } catch (error) {
      reject(error)
    }
  })
}

const getBranchWethAllowance = (owner, isProd = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      const web3 = createAlchemyWeb3(
        `https://${
          isProd ? 'eth-mainnet.alchemyapi.io' : 'polygon-mumbai.g.alchemy.com'
        }/v2/${isProd ? ALCHEMY_PROD : ALCHEMY_DEV}`,
      )

      const WETH = new web3.eth.Contract(
        ERC20_ABI,
        isProd ? WETH_ADDRESS_PROD : WETH_ADDRESS_DEV,
      )

      const allowance = await WETH.methods
        .allowance(
          owner,
          isProd ? LAUNCH_BRANCH_ADDRESS_PROD : LAUNCH_BRANCH_ADDRESS_DEV,
        )
        .call()
      resolve(allowance)
    } catch (error) {
      reject(error)
    }
  })
}

const approveBranchWeth = (owner, isProd = false, walletConnect) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (window.ethereum || walletConnect.isWalletConnect) {
        const web3 = new Web3(
          walletConnect.isWalletConnect
            ? walletConnect.provider
            : window.ethereum,
        )

        const WETH = new web3.eth.Contract(
          ERC20_ABI,
          isProd ? WETH_ADDRESS_PROD : WETH_ADDRESS_DEV,
        )

        const allowance = await WETH.methods
          .approve(
            isProd ? LAUNCH_BRANCH_ADDRESS_PROD : LAUNCH_BRANCH_ADDRESS_DEV,
            '1000000000000000000000000',
          )
          .send({ from: owner })
        resolve(true)
      } else {
        reject('No injected web3')
      }
    } catch (error) {
      reject(error)
    }
  })
}

const approveFrontdesk = (owner, isProd = false, walletConnect) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (window.ethereum || walletConnect.isWalletConnect) {
        const web3 = new Web3(
          walletConnect.isWalletConnect
            ? walletConnect.provider
            : window.ethereum,
        )

        const WETH = new web3.eth.Contract(
          ERC20_ABI,
          isProd ? WETH_ADDRESS_PROD : WETH_ADDRESS_DEV,
        )

        const allowance = await WETH.methods
          .approve(
            isProd ? FRONTDESK_ADDRESS_PROD : FRONTDESK_ADDRESS_DEV,
            '1000000000000000000000000',
          )
          .send({ from: owner })
        resolve(true)
      } else {
        reject('No injected web3')
      }
    } catch (error) {
      reject(error)
    }
  })
}

const makeBranchDeposit = (owner, amount, isProd = false, walletConnect) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (window.ethereum || walletConnect.isWalletConnect) {
        const web3 = new Web3(
          walletConnect.isWalletConnect
            ? walletConnect.provider
            : window.ethereum,
        )

        const lehman = new web3.eth.Contract(
          Lehman.abi,
          isProd ? LEHMAN_PROD : LEHMAN_DEV,
        )

        let branch = await lehman.methods
          .branches(
            isProd ? MILADY_ADDRESS_PROD : MILADY_ADDRESS_DEV,
            isProd ? WETH_ADDRESS_PROD : WETH_ADDRESS_DEV,
            0,
          )
          .call()

        let frontdeskContract = new web3.eth.Contract(
          FrontDesk.abi,
          isProd ? FRONTDESK_ADDRESS_PROD : FRONTDESK_ADDRESS_DEV,
        )

        await frontdeskContract.methods
          .offerSubprimeMortgage(branch, owner, amount)
          .send({ from: owner })

        resolve(true)
      } else {
        reject('No injected web3')
      }
    } catch (error) {
      reject(error)
    }
  })
}

const borrowFromBranch = (
  owner,
  nftId,
  amount,
  isProd = false,
  walletConnect,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (window.ethereum || walletConnect.isWalletConnect) {
        const web3 = new Web3(
          walletConnect.isWalletConnect
            ? walletConnect.provider
            : window.ethereum,
        )

        const lehman = new web3.eth.Contract(
          Lehman.abi,
          isProd ? LEHMAN_PROD : LEHMAN_DEV,
        )

        let branch = await lehman.methods
          .branches(
            isProd ? MILADY_ADDRESS_PROD : MILADY_ADDRESS_DEV,
            isProd ? WETH_ADDRESS_PROD : WETH_ADDRESS_DEV,
            0,
          )
          .call()

        let branchContract = new web3.eth.Contract(Branch.abi, branch)

        await branchContract.methods
          .takeSubprimeMortgage(nftId, amount)
          .send({ from: owner })
        resolve(true)
      } else {
        reject('No injected web3')
      }
    } catch (error) {
      reject(error)
    }
  })
}

const repayLoan = (owner, loanId, isProd = false, walletConnect) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (window.ethereum || walletConnect.isWalletConnect) {
        const web3 = new Web3(
          walletConnect.isWalletConnect
            ? walletConnect.provider
            : window.ethereum,
        )
        const lehman = new web3.eth.Contract(
          Lehman.abi,
          isProd ? LEHMAN_PROD : LEHMAN_DEV,
        )

        let branch = await lehman.methods
          .branches(
            isProd ? MILADY_ADDRESS_PROD : MILADY_ADDRESS_DEV,
            isProd ? WETH_ADDRESS_PROD : WETH_ADDRESS_DEV,
            0,
          )
          .call()

        let branchContract = new web3.eth.Contract(Branch.abi, branch)

        const canLiquidate = await branchContract.methods
          .canLiquidate(loanId)
          .call()

        console.log('Branch can liquidate loan', canLiquidate)

        if (canLiquidate) {
          console.log('liquidate')
          await branchContract.methods.evictOwner(loanId).send({ from: owner })
        } else {
          await branchContract.methods
            .closeSubprimeMortgage(loanId)
            .send({ from: owner })
        }

        resolve(true)
      } else {
        reject('No injected web3')
      }
    } catch (error) {
      reject(error)
    }
  })
}

const repackageLoan = (owner, loanId, isProd = false, walletConnect) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (window.ethereum || walletConnect.isWalletConnect) {
        const web3 = new Web3(
          walletConnect.isWalletConnect
            ? walletConnect.provider
            : window.ethereum,
        )
        const lehman = new web3.eth.Contract(
          Lehman.abi,
          isProd ? LEHMAN_PROD : LEHMAN_DEV,
        )

        let branch = await lehman.methods
          .branches(
            isProd ? MILADY_ADDRESS_PROD : MILADY_ADDRESS_DEV,
            isProd ? WETH_ADDRESS_PROD : WETH_ADDRESS_DEV,
            0,
          )
          .call()

        let branchContract = new web3.eth.Contract(Branch.abi, branch)

        const loan = await branchContract.methods.getLoan(loanId).call()

        const currentLoanDebt = Number(utils.formatEther(loan.debt))

        await branchContract.methods
          .repackageSubprime(
            loanId,
            currentLoanDebt > 0.5 ? '300000000000000000' : '200000000000000000',
          )
          .send({ from: owner })
        resolve(true)
      } else {
        reject('No injected web3')
      }
    } catch (error) {
      reject(error)
    }
  })
}

const getLoansByOwner = (owner, isProd = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (window.ethereum) {
        const web3 = createAlchemyWeb3(
          `https://${
            isProd
              ? 'eth-mainnet.alchemyapi.io'
              : 'polygon-mumbai.g.alchemy.com'
          }/v2/${isProd ? ALCHEMY_PROD : ALCHEMY_DEV}`,
        )
        let branchContract = new web3.eth.Contract(
          Branch.abi,
          isProd ? LAUNCH_BRANCH_ADDRESS_PROD : LAUNCH_BRANCH_ADDRESS_DEV,
        )

        const events = await branchContract.getPastEvents(
          'TookSubprimeMortgage',
          { filter: { take: owner }, fromBlock: '0', toBlock: 'latest' },
        )

        console.log(events)
        let promises = []
        events.forEach((loan) => {
          promises.push(
            branchContract.methods.getLoan(loan.returnValues.loanId).call(),
          )
        })

        let loans = await Promise.all(promises)

        events.forEach((loan, index) => {
          let newLoan = { ...loans[index], loanId: loan.returnValues.loanId }
          loans[index] = newLoan
        })

        console.log(loans)
        resolve(loans)
      } else {
        reject('No injected web3')
      }
    } catch (error) {
      reject(error)
    }
  })
}

const mintDevWeth = (owner) => {
  return new Promise(async (resolve, reject) => {
    console.log(owner)
    try {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum)

        const devWeth = new web3.eth.Contract(
          [
            {
              type: 'function',
              name: 'mint',
              inputs: [
                { name: 'to', type: 'address' },
                { name: 'amount', type: 'uint256' },
              ],
            },
          ],
          WETH_ADDRESS_DEV,
        )

        await devWeth.methods
          .mint(owner, '10000000000000000000')
          .send({ from: owner })

        resolve(true)
      } else {
        reject('No injected web3')
      }
    } catch (error) {
      reject(error)
    }
  })
}

const mintDevNft = (owner) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum)

        const devNft = new web3.eth.Contract(
          [
            {
              type: 'function',
              name: 'safeMint',
              inputs: [{ name: 'to', type: 'address' }],
            },
          ],
          MILADY_ADDRESS_DEV,
        )

        await devNft.methods.safeMint(owner).send({ from: owner })

        resolve(true)
      } else {
        reject('No injected web3')
      }
    } catch (error) {
      console.log(error)
      reject(error)
    }
  })
}

const getBranch = (isProd = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      const web3 = createAlchemyWeb3(
        `https://${
          isProd ? 'eth-mainnet.alchemyapi.io' : 'polygon-mumbai.g.alchemy.com'
        }/v2/${isProd ? ALCHEMY_PROD : ALCHEMY_DEV}`,
      )

      const lehman = new web3.eth.Contract(
        Lehman.abi,
        isProd ? LEHMAN_PROD : LEHMAN_DEV,
      )

      let branch = await lehman.methods
        .branches(
          isProd ? MILADY_ADDRESS_PROD : MILADY_ADDRESS_DEV,
          isProd ? WETH_ADDRESS_PROD : WETH_ADDRESS_DEV,
          0,
        )
        .call()

      //console.log(branch)
      let branchContract = new web3.eth.Contract(Branch_ABI, branch)

      let promises = [branchContract.methods.branchStorage().call()]

      let [data] = await Promise.all(promises)

      let expandedData = {
        ...data,
        maxLoanSize: '0.5',
        maxLoanDebt: '0.6',
      }

      // Duplicate data field cleanup in expandedData object
      for (let i = 0; i < 18; i++) delete expandedData[i]
      console.log(expandedData)
      resolve(expandedData)
    } catch (error) {
      console.log(error)
      reject(error)
    }
  })
}

export {
  getNfts,
  getLoansByOwner,
  getBranch,
  getBranchNftApproval,
  giveBranchNftApproval,
  getBranchWethAllowance,
  approveBranchWeth,
  getWeth,
  getFrn,
  getBranchLp,
  makeBranchDeposit,
  borrowFromBranch,
  repayLoan,
  repackageLoan,
  getFrontdeskWethAllowance,
  approveFrontdesk,
  mintDevWeth,
  mintDevNft,
  stakeLpToken,
  getStakedLpToken,
  stakeFRN,
  getStakedFRN,
  getLpTokenStakingAllowance,
  approveLpTokenStaking,
}
