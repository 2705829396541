/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import ReactTimeAgo from 'react-time-ago'

import { utils } from 'ethers'

import BAYC from './assets/images/bayc_logo.png'
import Milady from './assets/images/milady.jpeg'
import Moonbirds from './assets/images/moonbird.png'

import Spinner from './Spinner'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Loans(props) {
  const {
    connectedAccount,
    weth,
    loans,
    approveBranchWeth,
    isApprovingBranchWeth,
    branchWethAllowance,
    repayLoan,
    repackageLoan,
    activeLoan,
    isRepayingLoan,
    isRepackingLoan,
    activeAction,
    isLoansLoading,
  } = props

  let branches = [
    {
      name: 'Milady',
      image: Milady,
      loans: loans,
    },
  ]

  const loanAction = (type, loan) => {
    if (!isApprovingBranchWeth && !isRepayingLoan && !isRepackingLoan) {
      if (
        Number(utils.formatEther(branchWethAllowance.toString())) >
        Number(utils.formatEther(loan.debt.toString()))
      ) {
        if (type === 'repay') {
          repayLoan(loan.loanId)
        } else {
          repackageLoan(loan.loanId)
        }
      } else {
        approveBranchWeth(loan.loanId, type)
      }
    }
  }

  const renderButtonContent = (type, loan) => {
    if (type === 'repackage') {
      if (loan.loanId !== activeLoan) {
        return 'Repackage'
      } else {
        if (isApprovingBranchWeth || isRepackingLoan) {
          if (isApprovingBranchWeth && activeAction !== 'repackage') {
            return 'Repackage'
          }
          return (
            <div
              style={{
                transform: 'translate(28px, -2px)',
                width: '72px',
                height: '20px',
              }}
            >
              <Spinner color="green" />
            </div>
          )
        } else {
          return 'Repackage'
        }
      }
    } else {
      if (loan.loanId !== activeLoan) {
        return 'Repay'
      } else {
        if (isApprovingBranchWeth || isRepayingLoan) {
          if (isApprovingBranchWeth && activeAction !== 'repay') {
            return 'Repay'
          }
          return (
            <div
              style={{
                transform: 'translate(13px, -1px)',
                width: '42px',
                height: '20px',
              }}
            >
              <Spinner color="white" />
            </div>
          )
        } else {
          return 'Repay'
        }
      }
    }
  }

  return (
    <div className="w-full h-full mx-auto space-y-4 px-6 pt-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <div className="flex flex-col justify-start items-start">
            <h2 className="text-3xl leading-8 font-semibold tracking-tight text-gray-700 sm:text-3xl">
              Active Loans
            </h2>
            <p className="mt-1 font-mono max-w-3xl text-sm text-gray-400">
              Overview of currently outstanding loans by the connected user
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-300  ">
              <table className="min-w-full">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Collection
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Total debt
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Loan start
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Health rating
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {branches.map((branch) => (
                    <Fragment key={branch.name}>
                      <tr className="border-t border-gray-200 w-full">
                        <th
                          colSpan={5}
                          scope="colgroup"
                          className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                        >
                          <div className="col-span-5 space-x-2 flex flex-row items-center">
                            <img
                              src={branch.image}
                              className="h-6 w-6 rounded-full"
                            />
                            <div> {branch.name}</div>
                          </div>
                        </th>
                      </tr>
                      {branch.loans
                        .filter(
                          (loan) =>
                            loan.debt !== '0' &&
                            loan.owner.toLowerCase() === connectedAccount,
                        )
                        .map((loan, loanIdx) => (
                          <tr
                            key={loan.house}
                            className={classNames(
                              loanIdx === 0
                                ? 'border-gray-300'
                                : 'border-gray-200',
                              'border-t',
                            )}
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 ">
                              {branch.name + ' #' + loan.house}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {Number(
                                utils.formatEther(loan.debt),
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 7,
                                maximumFractionDigits: 7,
                              }) + ' ETH'}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {loan.ts && (
                                <ReactTimeAgo
                                  date={new Date(loan.ts * 1000)}
                                  locale="en-US"
                                />
                              )}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <div className="flex flex-row space-x-2 items-center">
                                <div
                                  className={`h-4 w-4 rounded-full ${
                                    Number(
                                      utils.formatEther(loan.debt),
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 7,
                                      maximumFractionDigits: 7,
                                    }) >= 0.5
                                      ? 'bg-red-200'
                                      : Number(
                                          utils.formatEther(loan.debt),
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 7,
                                          maximumFractionDigits: 7,
                                        }) > 0.25
                                      ? 'bg-yellow-200'
                                      : 'bg-green-200'
                                  }`}
                                />
                                <div>
                                  {Number(
                                    utils.formatEther(loan.debt),
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 7,
                                    maximumFractionDigits: 7,
                                  }) >= 0.5
                                    ? 'Low'
                                    : Number(
                                        utils.formatEther(loan.debt),
                                      ).toLocaleString(undefined, {
                                        minimumFractionDigits: 7,
                                        maximumFractionDigits: 7,
                                      }) > 0.25
                                    ? 'Moderate'
                                    : 'High'}
                                </div>
                              </div>
                            </td>
                            <td className=" whitespace-nowrap py-4 text-sm font-medium pr-2 sm:pr-6 ">
                              <div className="w-full h-full flex flex-row justify-end space-x-4">
                                {Number(
                                  utils.formatEther(loan.debt),
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 7,
                                  maximumFractionDigits: 7,
                                }) <= 0.25 && (
                                  <div className="py-2 px-2 border border-green-200 text-green-500 rounded-lg cursor-default opacity-50">
                                    Repackage
                                  </div>
                                )}

                                {Number(
                                  utils.formatEther(loan.debt),
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 7,
                                  maximumFractionDigits: 7,
                                }) > 0.25 && (
                                  <div
                                    onClick={() =>
                                      loanAction('repackage', loan)
                                    }
                                    className="py-2 px-2 border border-green-200 text-green-500 rounded-lg cursor-pointer hover:border-green-400"
                                  >
                                    {renderButtonContent('repackage', loan)}
                                  </div>
                                )}

                                <div
                                  onClick={() => loanAction('repay', loan)}
                                  className="py-2 px-2 bg-green-400 text-white rounded-lg cursor-pointer hover:bg-green-500"
                                >
                                  {renderButtonContent('repay', loan)}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            {isLoansLoading && (
              <div className="w-full pt-4 flex flex-row justify-center">
                <Spinner color="green" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
